import React from 'react';

export const WebpContext = React.createContext<boolean>(false);

const WebpProvider: React.FC = ({ children }) => {
  const [webpSupported, setWebpSupported] = React.useState(true);

  async function supportsWebp(
    setWebpSupported: (isSupported: boolean) => void,
  ) {
    if (!self.createImageBitmap) {
      setWebpSupported(false);
      return;
    }

    const webpData =
      'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    createImageBitmap(blob).then(
      () => setWebpSupported(true),
      () => setWebpSupported(false),
    );
  }

  React.useEffect(() => {
    supportsWebp(setWebpSupported);
  }, []);

  return (
    <WebpContext.Provider value={webpSupported}>
      {children}
    </WebpContext.Provider>
  );
};

export default WebpProvider;
