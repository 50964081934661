import React from 'react';
import browserLang from 'browser-lang';
import { navigate } from 'gatsby';
import useLocale from './useLocale';

const useLangRedirect = () => {
  const { languages, defaultLanguage, language } = useLocale();

  React.useEffect(() => {
    const cookies = document.cookie.split(';');
    const r360LocaleEntry = cookies.find((item) =>
      item.trim().startsWith('locale='),
    );
    const r360Locale = r360LocaleEntry && r360LocaleEntry.split('=')[1];
    const nfLangEntry = cookies.find((item) =>
      item.trim().startsWith('nf_lang='),
    );
    const nfLang = nfLangEntry && nfLangEntry.split('=')[1];
    let to = '';
    let force = false;

    // Force redirect if new locale specified
    if (r360Locale && r360Locale !== nfLang) {
      to = r360Locale;
      force = true;
      document.cookie = 'nf_lang=; path=/; max-age=0'; // NOTE: Remove legacy cookie first
      document.cookie = `nf_lang=${to}; domain=${process.env.GATSBY_COOKIE_DOMAIN}; path=/; max-age=31536000`;
    }

    // Sync nf_lang with shared locale
    if (!to) {
      if (r360Locale) {
        to = r360Locale;
        document.cookie = 'nf_lang=; path=/; max-age=0'; // NOTE: Remove legacy cookie first
        document.cookie = `nf_lang=${to}; domain=${process.env.GATSBY_COOKIE_DOMAIN}; path=/; max-age=31536000`;
      }
    }

    // Reproduce shared locale from nf_lang
    if (!to) {
      if (nfLang) {
        to = nfLang;
        document.cookie = `locale=${to}; domain=${process.env.GATSBY_COOKIE_DOMAIN}; path=/; max-age=31536000`;
      }
    }

    // Fallback nf_lang with browser language
    if (!to) {
      to = browserLang({ languages: languages });
      document.cookie = 'nf_lang=; path=/; max-age=0'; // NOTE: Remove legacy cookie first
      document.cookie = to
        ? `nf_lang=${to}; domain=${process.env.GATSBY_COOKIE_DOMAIN}; path=/; max-age=31536000`
        : `nf_lang=; domain=${process.env.GATSBY_COOKIE_DOMAIN}; path=/; max-age=0`;
    }

    // Set to the default language if no page exist for the language
    if (!languages.includes(to)) {
      to = defaultLanguage;
    }

    // Redirect by client if necesarry
    if (language === defaultLanguage || force) {
      if (to && to !== language) {
        let pathname = window.location.pathname;
        if (language !== defaultLanguage) {
          pathname = pathname.replace(/^\/[^/]*\//, '/');
        }
        // NOTE: Only for localhost and Chrome without nf_lang
        let newUrl = pathname;
        if (to !== defaultLanguage) {
          newUrl = `/${to}${newUrl}`;
        }
        newUrl = newUrl.replace(/\/*$/, '/');
        navigate(newUrl);
      }
    }
  }, [languages, defaultLanguage, language]);
};

export default useLangRedirect;
